import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin.js";
gsap.registerPlugin(ScrollTrigger, MorphSVGPlugin);

export class Teaser {
  constructor(teaser, options) {
    this.teaser = teaser
    this.options = options
    this.stStart = this.options.start || "top 80%"
    this.stEnd = this.options.end || "bottom 30%"
    this.img = this.teaser.querySelector(".teaser__img img")
    this.imgDiv = this.teaser.querySelector(".teaser__img")
    // this.copySvgShape()
    if(this.options.translateId) {
      this.initTranslate()
    }
    this.initMorph()
  }

  copySvgShape() {
    // @TODO copy shape to teaser__shape
  }

  fixClipPath(targets) {
    const agent = navigator.userAgent || "";
    if (~agent.indexOf("Safari") && agent.indexOf("Chrome") < 0) { // only apply the fix if it's Safari (otherwise, save the CPU cycles)
      targets = gsap.utils.toArray(targets);
      const clipPaths = targets.map(el => window.getComputedStyle(el).clipPath),
            func = (el, i) => {
              el.style.clipPath = "none";
              el.offsetWidth; //force a style recalculation
              el.style.clipPath = clipPaths[i];
            };
      return () => targets.forEach(func);
    }
  }

  initMorph() {
    let shapeId = this.teaser.dataset.shape
    let shapeIndex = this.options.shapeIndex || "auto"
    let clipStart = body.querySelector(`#${shapeId}__clip-start`)
    let clipEnd = body.querySelector(`#${shapeId}__clip-end`)

    gsap.to(clipStart, {
      morphSVG: {
        shape: clipEnd,
        // type: "rotational",
        shapeIndex: shapeIndex
      },
      scrollTrigger: {
        trigger: this.teaser,
        // markers: true,
        scrub: true,
        start: this.stStart,
        end: this.stEnd,
      },
      onUpdate: (this.imgDiv.getAttribute("style")) ? this.fixClipPath(this.imgDiv) : this.fixClipPath(this.img)
    });
  }

  initTranslate() {
    gsap.to(this.img, {
      x: (this.options.translateId === 'from-right' || this.options.translateId === 'from-vertical' ? "-20%" : "+20%"),
      y: "+20%",
      scale: 1.4,
      scrollTrigger: {
        trigger: this.teaser,
        // markers: true,
        scrub: true,
        start: this.stStart,
        // end: this.stEnd,
        // toggleActions: "play none resume reverse"
      }
    })
  }
}

// initialize
window.addEventListener('load', function () {
  const teasers = document.querySelectorAll('.teaser')
  gsap.utils.toArray(teasers).forEach((teaser) => {
    let options = JSON.parse(teaser.dataset.options) || {}
    new Teaser(teaser, options)
  })
})
