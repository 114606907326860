import {tns} from 'tiny-slider/src/tiny-slider'
export class Slider {
  constructor(slider) {
    this.slides = slider.querySelector('.slider__slides')
    this.controls = slider.querySelector('.slider__controls')
    // this.nav = slider.querySelector('.slider__nav')
    this.initTns()
  }

  initTns() {
    let slider = tns({
      container: this.slides,
      nav: false,
      controlsContainer: this.controls,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3500,
      items: 1,
      loop: true,
    })
  }
}

//init
document.querySelectorAll('.js-slider').forEach((slider) => {
  new Slider(slider)
})
