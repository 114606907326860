import { gsap } from "gsap";

gsap.utils.toArray('.js-reveal').forEach((container) => {
  gsap.from(container,{
    scrollTrigger: {
      trigger: container,
      // markers: true,
      start: "top 80%",
    },
    y: 120,
    opacity: 0,
    // duration: 1
    // scale: .9
  })
})
