import lw from 'lottie-web'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

/**
 * @todo error handling on init options
 * @todo pass speed option to animation.setSpeed(0.2) in LottieOnLoad()
 */
export class Lottie {
  constructor(container, vars) {
    this.json = container.dataset.src
    this.initLottie(container, vars)
  }

  /**
   * scroll based lottie animation
   * @param vars: lottie-web and ScrollTrigger parameters
   * @pararm
   * @url https://greensock.com/docs/v3/HelperFunctions
   */
  LottieScrollTrigger(vars) {
    let playhead = {frame: 0},
      target = gsap.utils.toArray(vars.target)[0],
      speeds = {slow: "+=2000", medium: "+=1000", fast: "+=500"},
      st = {trigger: target, pin: true, start: "top top", end: speeds[vars.speed] || "+=1000", scrub: 1},
      animation = lw.loadAnimation({
        container: target,
        renderer: vars.renderer || "svg",
        loop: false,
        autoplay: false,
        path: vars.path
      });
    for (let p in vars) { // let users override the ScrollTrigger defaults
      st[p] = vars[p];
    }

    animation.addEventListener("DOMLoaded", function() {
      gsap.to(playhead, {
        frame: animation.totalFrames - 1,
        ease: "none",
        onUpdate: () => animation.goToAndStop(playhead.frame, true),
        scrollTrigger: st
      });
    });
  }

  /**
   * Play lottie on hover
   * @param vars
   * @constructor
   */
  LottieOnHover(vars) {
    let target = gsap.utils.toArray(vars.target)[0],
    animation = lw.loadAnimation({
      container: target,
      renderer: vars.renderer || "svg",
      loop: false,
      autoplay: false,
      path: vars.path,
    });
    target.addEventListener("mouseover", e => {
      animation.setDirection(1)
      animation.play()
    })
    target.addEventListener("mouseout", e => {
      animation.setDirection(-1)
      animation.play()
    })
  }

  /**
   * Play lottie on load
   * @param vars
   * @constructor
   */
  LottieOnLoad(vars) {
    let target = gsap.utils.toArray(vars.target)[0],
      animation = lw.loadAnimation({
        container: target,
        renderer: vars.renderer || "svg",
        loop: vars.loop || true,
        autoplay: vars.autoplay || true,
        path: vars.path,
      });

    animation.addEventListener("DOMLoaded", function() {
      // animation.setSpeed(0.2)
      animation.play()
    })
  }

  /**
   * Init lottie
   * @param container div containing lottiefile
   * @param vars JSON: options
   */
  initLottie(container, vars) {
    if (vars.play === "onHover") {
      this.LottieOnHover({
        target: container,
        path: this.json
      })
    } else if (vars.onscroll) {
      this.LottieScrollTrigger({
        target: container,
        markers: vars.markers || false,
        path: this.json,
        speed: "fast",
        start: vars.start || "top top",
        pin: vars.pin || false,
        pinSpacing: false,
        scrub: 2 // seconds it takes for the playhead to "catch up"
        // you can also add ANY ScrollTrigger values here too, like trigger, start, end, onEnter, onLeave, onUpdate, etc. See https://greensock.com/docs/v3/Plugins/ScrollTrigger
      });
    } else {
      this.LottieOnLoad({
        target: container,
        path: this.json
      })
    }
  }
}

// initialize
document.querySelectorAll('.lottie__container').forEach((container) => {

  let pin = container.querySelector('.lottie__pin')
  let lottie = container.querySelector('.lottie')
  let options = JSON.parse(lottie.dataset.options) || {}
  options.pin = pin
  new Lottie(lottie, options)
})
