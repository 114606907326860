import { CountUp } from "countup.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

export class TeaserLine {
  constructor(container) {
    this.container = container
    this.title = container.querySelector('.teaser-line__title')
    this.text = container.querySelector('.teaser-line__text')
    this.line = container.querySelector('.teaser-line__line')
    this.lottie = container.querySelector('.lottie__container')
    this.fact = container.querySelector('.teaser-line__title h3')
    this.init()
  }

  init() {
    let tl = gsap.timeline({
      scrollTrigger: {
        // markers: true,
        trigger: this.container,
        start: "top 90%",
      },
      onStart: () => {
        setTimeout(() => {
          let options = {
            duration: 3,
            separator: '.',
            decimal: ',',
          }
          let demo = new CountUp(this.fact, this.fact.innerHTML, options);
          demo.start();
        }, 1000)
      },
    })
    //text animation
    let textAnimationFrom = {
      // duration: .35,
      // delay: 1,
      opacity: 0,
      y: 120,
    }

    //lottie animation
    let lottieAnimationFrom = {
      // duration: .35,
      opacity: 0,
      y: 120,
      // delay: .5
    }

    //line animation
    let lineAnimationFrom = {
      width: 0,
      duration: 1,
      // delay: 1,
    }
    tl
    .from(this.line, lineAnimationFrom)
    .from(this.lottie, lottieAnimationFrom)
    .from(this.title, textAnimationFrom)
    .from(this.text, textAnimationFrom)

  }
}

// initialize after content load
window.addEventListener('load', function () {
  document.querySelectorAll('.teaser-line__container').forEach((container) => {
    new TeaserLine(container)
  })
}, false)
